/* CSS */
.menu-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #060b26;
}

.logged-off-navbar {
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    border-bottom: 1px solid #ff8a00;
    padding: 10px 40px;
    transition: background 0.3s ease, backdrop-filter 0.3s ease;
    padding: 0 1rem !important;
    height: 80px !important;
}

/* Optional CSS for mobile view, toggling etc. */


.logo-link {
    text-decoration: none;
}
.logo-link:hover {
    text-decoration: none;
}
.logged-off-logo {
    font-size: 2rem;
    font-weight: bold;
    color: #ff8a00;
}
.menu-icon {
    display: none;
    font-size: 1.5rem;
    color: #ff8a00 !important;
    cursor: pointer;
}
.menu-items {
    display: flex;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
}
.menu-items a {
    text-decoration: none;
    color: white !important;
    font-weight: bold;
    transition: color 0.3s ease;
    padding-top: 0.5rem !important;
    margin: 0 0.5rem !important;
}
.menu-items a:hover {
    color: white !important;
}
.btn-login-homepage {
    background-color: #ff8a00;
    color: #000 !important;
    padding: 5px 20px;
    border-radius: 15px;
    margin-top: -5px !important;
}
.btn-demo-homepage {
    background-color: #ff8a00;
    color: #1b2033 !important;
    padding: 5px 20px;
    border: 1px solid #ff8a00;
    border-radius: 15px;
    margin-top: -5px !important;
    margin-right: -1.5rem !important;
}
/* Mobile styling */
@media (max-width: 720px) {
    .menu-icon {
        display: block;
    }
    .menu-items {
        position: absolute;
        top: 70px;
        right: 0;
        flex-direction: column;
        width: 100%;
        max-width: 100vw;
        display: none;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        border-radius: 0 0 10px 10px;
        overflow: hidden;
        padding: 1rem !important;
    }

    .menu-items.open {
        display: flex;
        background-color: #060b26 !important;
    }

    .menu-items a {
        text-align: center;
        color: #060b26;
        padding: 0.5rem !important;
    }

    .btn-demo-homepage {
        margin-right: 0 !important;
    }
}
.home-language-dropdown {
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    padding: 8px 15px;
    border: 1px solid #fff; /* Border to match your UI */
    border-radius: 15px;
    appearance: none; /* Remove default arrow styling */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    outline: none; /* Remove focus outline */
    margin-left: auto;
    cursor: pointer;
    position: relative;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  
  .home-language-dropdown:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
  }
  
  .home-language-dropdown option {
    color: #000; /* Ensure options text is visible on dropdown */
    background: #fff; /* White background for dropdown items */
    padding: 10px 20px;
  }
  
  /* Add a custom arrow to replace the default one */
  .home-language-dropdown::after {
    content: '▼'; /* Custom arrow */
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  /* Optional: Style focus state */
  .home-language-dropdown:focus {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }