/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.onboarding-modal {
  background: #fff;
  border-radius: 10px;
  width: 500px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.onboarding-header h1 {
  margin: 0;
  font-size: 1.8rem;
  color: #333;
  justify-content: center;
  align-items: center;
}

.onboarding-header p {
  color: #555;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
}

.onboarding-steps {
  margin-top: 20px;
}

.onboarding-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease;
}

.onboarding-card:hover {
  background: #f1f1f1;
}

.onboarding-time {
  font-size: 0.85rem;
  color: #888;
}

.onboarding-status {
  font-size: 1.5rem;
  color: #4caf50; /* Green color for success */
}


.onboarding-icon {
  font-size: 1.8rem;
  margin-right: 15px;
}

.onboarding-content {
  flex-grow: 1; /* Ensure the text takes the middle space */
}

.onboarding-content h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.onboarding-content p {
  margin: 5px 0;
  color: #555;
}

.onboarding-time {
  font-size: 0.85rem;
  color: #888;
}

.onboarding-status {
  font-size: 1.5rem;
  color: #4caf50; /* Green color for success */
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-icon {
  font-size: 1.8rem;
}
.onboarding-card a {
  text-decoration: none;
}
.onboarding-card.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.how-to-get-api-link {
  text-align: left !important;
  font-size: 0.8rem !important;
}