.font-orange {
  color: #ff8a00 !important;
}
.centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #f4f5f9;
  }
  
  .landing-card {
    background-color: yellowgreen;
    text-align: center;
    padding: 40px;
    border-radius: 20px;
  }
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background-color: #f0f2f5;
  }
  
  .login-card {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 35rem;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 0.5rem;
  }
  
  .form-control.is-invalid {
    border-color: #dc3545;
  }
  
  .invalid-feedback {
    color: #dc3545;
  }
  
  .btn-login {
    width: 100%;
    padding: 1rem;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  
  .btn-login:hover {
    background-color: #0056b3;
  }
  
  .forgot-password {
    text-align: right;
    margin-top: 0.5rem;
    color: #007bff;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }

  select {
    padding: 0rem 0.7rem !important;
  }
  
  /* Responsive styles */
  @media (max-width: 576px) {
    .login-card {
      padding: 1rem;
    }
  }
  