.footer {
    position: relative;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: #fff;
    background-color: transparent; /* Transparent background */
    backdrop-filter: blur(5px); /* Slight blur for readability */
    -webkit-backdrop-filter: blur(5px); /* Safari support */
    z-index: 10;
}

.footer-ul {
    list-style: none;
    padding: 0;
}

.footer-ul li {
    padding: 10px;
}

.footer-ul li a {
    text-decoration: none;
    color: #fff; /* Ensures readability on darker backgrounds */
    transition: color 0.3s ease;
}

.footer-ul li a:hover {
    color: #ff8a00; /* Optional hover color to match your design */
}

.social-icons {
    margin-top: 1rem;
}  

.social-icons a {
    text-decoration: none;
    transition: transform 0.3s ease;
    padding: 1rem;
    color: #fff; /* Ensures icons are visible */
}

.social-icons a:hover {
    transform: scale(1.1);
    color: #ff8a00; /* Optional color change on hover */
}

/* Ensure logo images and icons in footer maintain visibility and styling */
.logo-row img {
    opacity: 0.9;
    transition: opacity 0.3s ease;
}

.logo-row img:hover {
    opacity: 1;
}
.footer-copyright {
    margin-top: 0.5rem !important;
}
.underline-orange-thin {
    text-decoration: underline !important;
    text-decoration-color: #ff8a00 !important;
    text-decoration-thickness: 1px !important;
    text-underline-offset: 2px !important; 
}
.text-legal {
    font-size: 0.9rem !important;
}

.language-dropdown {
    margin-bottom: 2rem !important;
    width: 25% !important;
    border-radius: 20px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/* Mobile styling */
@media (max-width: 720px) {
    .footer {
      text-align: center;
      padding: 0 !important;
    }
    .footer-subtitle {
        padding: 0 !important;
    }
    .footer-ul {
        margin-top: 0.5rem !important;
        margin-bottom: 0rem !important;
    }
    .footer-ul-2 {
        margin-top: 0rem !important;
    }
    .footer-ul li {
        margin-top: 0.1rem !important;
        padding-top: 0.1rem !important;
    }
    .footer-copyright {
        margin-top: 0.7rem !important;
    }
    .underline-orange-thin {
        font-size: 1.1rem !important;
    }
    .language-dropdown {
        margin-top: 2rem !important;
        width: 75% !important;
    }
}