



.nav-menu {
  background-color: #060b26;
  width: 18rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 10000;
}

.nav-menu.active {
  left: 0;
  transition: 250ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.setting {
  list-style: none;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 16px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.sideBarSpan {
  margin-left: 16px;
}

.nav-bar-span {
  margin-top: 0.6rem !important;
  text-align: left !important;
}

/* Style for the active sidebar item */
.nav-text.active a {
  background-color: #1a83ff; /* Highlight color for active item */
  color: #ffffff; /* Text color */
  font-weight: bold; /* Optional: Make the text bold */
}

.nav-text.active a:hover {
  background-color: #1a83ff; /* Keep the same color on hover for active item */
}
/* General Navbar Styling */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #060b26 !important;
  height: 80px;
  color: #fff;
  position: fixed;
  padding: 0 16px !important;
}

/* Menu Bars */
.menu-bars {
  flex-shrink: 0;
  cursor: pointer;
  margin-left: 15px;
}

/* Current Page Name */
.current-page-name {
  margin-left: 10px;
  margin-top: 10px;
  flex-grow: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff !important;
}

/* Logo */
.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  flex-grow: 0;
}

/* Right Section */
.right-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Business Name */
.business-name {
  font-weight: 900;
  margin: 0;
  color: #fff !important;
  margin-right: 10px;
}

/* Language Dropdown */
.language-dropdown {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  height: 42px;
  line-height: 36px;
  padding: 0 15px;
  border: 1px solid #fff;
  border-radius: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}

.language-dropdown:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Subtle hover effect */
}

.language-dropdown option {
  color: #000; /* Ensure options text is visible on dropdown */
  background: #fff; /* White background for dropdown items */
  padding: 10px 20px;
}

/* Optional: Style focus state */
.language-dropdown:focus {
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .current-page-name {
    display: none;
  }

  .logo {
    margin: 0 auto;
  }

  .right-section {
    display: none;
  }
}
