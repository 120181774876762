/* Table Container */
.table-container {
  width: 100%;
  overflow-x: auto !important; /* Enables horizontal scroll on smaller screens */
  margin: 20px 0;
  color: #fff !important;
  padding: 2rem;
  border-radius: 20px;
  background-color: rgba(27, 32, 51, 0.8); /* Background for rounded edges */
}

/* Table Styling */
.responsive-table {
  width: 100%;
  border-collapse: separate; /* Allows for separate rounded borders */
  border-spacing: 0; /* Removes gaps between cells */
  border-radius: 20px;
  overflow: hidden; /* Clips the table within rounded edges */
}

/* Cell Styling */
.responsive-table th,
.responsive-table td {
  padding: 8px 12px;
  border: 0.5px solid #ff8a00 !important;
  text-align: center;
  background-color: rgba(27, 32, 51, 0.7); /* Cell background */
  white-space: nowrap; /* Prevents cell text from wrapping */
}

/* Header Styling */
.responsive-table th {
  font-weight: bold;
  color: #ff8a00;
  font-size: 1.2rem;
}

/* Rounded Corners for Specific Cells */
.responsive-table th:first-child {
  border-top-left-radius: 20px;
}
.responsive-table th:last-child {
  border-top-right-radius: 20px;
}
.responsive-table tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}
.responsive-table tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Adjust padding and font size on smaller screens */
  .responsive-table th,
  .responsive-table td {
    padding: 6px 10px;
    font-size: 0.9rem;
  }

  /* Ensure table container is scrollable horizontally on mobile */
  .table-container {
    padding: 1rem;
  }
}

/* Form Group Styling */
.form-group {
  position: relative;
  margin: 20px 0;
  padding: 10px 0;
}

/* Form Control Styling */
.form-control {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: #007bff;
}

/* Floating Label Styling */
.floating-label {
  position: absolute;
  top: 50%;
  left: 12px;
  color: #999;
  font-size: 16px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: 0.3s ease all;
}

/* Floating Label on Focus and Value */
.form-control:focus ~ .floating-label,
.form-control:not(:placeholder-shown) ~ .floating-label {
  top: -5px;
  left: 10px;
  color: #fff !important;
  font-size: 18px;
  padding: 0px 4px;
}

.floating-label-register {
  position: absolute;
  top: 55%;
  left: 12px;
  color: #999 !important;
  font-size: 16px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: 0.3s ease all;
}

/* Floating Label on Focus and Value */
.form-control:focus ~ .floating-label-register,
.form-control:not(:placeholder-shown) ~ .floating-label-register {
  top: 12px;
  left: 12px;
  color: #000 !important;
  background-color: #fff;
  font-size: 14px;
  padding: 0px 4px;
  font-weight: 600;

}

.floating-label-setting {
  position: absolute;
  top: 55%;
  left: 12px;
  color: #999 !important;
  font-size: 16px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: 0.3s ease all;
}

/* Floating Label on Focus and Value */
.form-control:focus ~ .floating-label-setting,
.form-control:not(:placeholder-shown) ~ .floating-label-setting {
  top: 15px;
  left: 10px;
  color: #000 !important;
  background-color: #fff;
  font-size: 14px;
  padding: 0px 4px;
  font-weight: 600;
}

/* Calculate-saving-form Styling */
.calculate-saving-form {
  position: relative;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
}

/* Calculate-saving-form Background and Blur Effect */
.calculate-saving-form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom right, rgba(27, 32, 51, 0.6), rgba(27, 32, 51, 0.8)) !important;
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  z-index: -1;
}
.card-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.metric-card {
  position: relative;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
  color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  font-size: 16px !important;
}

.metric-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom right, rgba(27, 32, 51, 0.6), rgba(27, 32, 51, 0.8));
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: -1; /* Ensure it stays behind the content */
}

.metric-card h4 {
  color: #fff !important;
  font-size: 1.4em;
  text-align: center !important;
  margin-bottom: 2rem;
}

.metric-card h5, .metric-card p {
  color: #fff !important;
  font-size: 1.2em;
}

.metric-values {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-metric {
  display: flex;
  justify-content: space-between;
}

.savings-p {
  padding: 0 8rem;
}

@media (max-width: 720px) {
  .savings-p {
    padding: 0 1.1rem;
    line-height: 1.5rem;
    font-size: 1.1rem;
  }
}

.calculate-saving-table, .calculate-saving-table-tr {
  background-color: transparent !important;
}