.verification-container {
    height: 100vh;
    background-color: #f8f9fa;
    color: #333;
    padding: 20px;
}

.verification-container h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #ff8a00;
}

.verification-message {
    font-size: 1.1rem;
    color: #555;
    max-width: 400px;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.waiting-text {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
}
