/* General table styling */
#main-table {
    width: 100%;
    border-collapse: collapse;
}

#main-table th, #main-table td {
    padding: 8px;
    text-align: center;
}

/* Hide table borders on desktop */
@media (min-width: 720px) {
    #main-table th, #main-table td {
        border: none;
        border-bottom: 1px solid #ddd; /* Add only the bottom border */
    }

    #main-table tr:last-child td {
        border-bottom: none; /* Remove the bottom border for the last row */
    }
}


/* Responsive design for small screens */
@media (max-width: 719px) {
    #main-table thead {
        display: none; /* Hide table headers */
    }

    #main-table tr {
        display: block;
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 8px;
    }

    #main-table td {
        display: flex;
        justify-content: space-between;
        padding: 8px;
        font-size: 14px;
        border: none; /* Remove borders for cleaner look */
        border-bottom: 1px solid #ddd;
        position: relative;
    }

    /* Label each cell with the column name */
    #main-table td::before {
        content: attr(data-label); /* Adds the column name as a label */
        font-weight: bold;
        color: #333;
        margin-right: 8px;
    }

    #main-table td:last-child {
        border-bottom: none; /* Remove border for the last cell */
    }
}

@media (max-width: 720px) {
    #main-table td[data-label="Options"] {
        display: flex;
        justify-content: space-between; /* Space between text and buttons */
        align-items: center;
    }

    #main-table td[data-label="Options"] .button-group {
        display: flex;
        gap: 10px; /* Spacing between buttons */
    }
}