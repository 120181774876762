/* registerBusiness.css */

.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../images/background.jpg') no-repeat center center/cover;
}

.register-card {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.register-form-input {
    margin-top: -1.5rem !important;
}