/* Prevent horizontal scroll on the entire body and html */
html, body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}

.font-light {
    color: #cad3e4 !important;
}

.font-orange {
    color: #ff8a00 !important;
}

/* The landing container is the main flexbox */
.landing-container {
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
    flex-direction: row; /* Text and image side by side on desktop */
    overflow: visible; /* Prevent any hidden overflow */
    padding: 0 15px;
    box-sizing: border-box;
    max-width: 100%; /* Ensure the container does not exceed the viewport width */
}

/* Row adjustments */
.row {
    width: 100%;
    display: flex;
    justify-content: center; /* Horizontally center the row */
    align-items: center;     /* Vertically center the row */
    margin: 0;
    padding: 0;
    max-width: 100%;
}
.overflow-x-none {
  overflow-x: hidden; /* Ensure no overflow */
}
/* Container for the text */
.landing-container-text {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center within the columns */
    align-items: center;
    width: 35%; /* Take 50% of the width on desktop */
    padding: 20px;
    box-sizing: border-box;
    max-width: 100%; /* Ensure it doesn't exceed the container's width */
    font-size: 20px; /* Set the font size */
    line-height: 30px; /* Follow the line-height provided */
    overflow-wrap: break-word; 
}

.first-title-container {
    font-weight: 900 !important;
}

.experience-title {
    font-weight: 900 !important;
}

.experience-subtitle {
    margin: 0 8rem;
}

.benefits-subtitle {
    margin: 0 12rem;
}

/* Container for the image */
.landing-container-image {
    display: flex;
    justify-content: center; /* Horizontally center within the columns */
    align-items: center;
    width: 50%; /* Take 50% of the width on desktop */
    padding: 20px;
    box-sizing: border-box;
    max-width: 90%; /* Ensure it doesn't exceed the container's width */
}

/* Ensure image doesn't cause overflow */
.landing-container-image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block; /* Ensures no inline space issues */
    object-fit: contain; 
}

.btn-services-contact {
    padding: 10px 40px; /* Padding for desktop */
    background-color: transparent; /* Transparent background */
    color: #f8f9fa; /* Light text color */
    border: 1px solid #ffffff; /* White border */
    border-radius: 20px; /* Rounded corners */
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    width: auto; /* Default width for desktop */
    text-align: center;
    margin-top: 2rem;
}

.btn-services-contact:hover {
    color: #060b26 !important; /* Transparent text on hover */
    background-color: #ffffff; /* White background on hover */ 
}

.btn-services-contact-2 {
    padding: 10px 40px; /* Padding for desktop */
    background-color: ffffff; /* Transparent background */
    color: #060b26 !important; /* Light text color */
    border: 1px solid #ffffff; /* White border */
    border-radius: 20px; /* Rounded corners */
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: auto;
    text-align: center;
    margin-top: 2rem;
}

.btn-services-contact-2:hover {
    color: #ffffff !important;
    background-color: transparent;
}

.experience-images {
    height: 10rem;
}

.benefits-top-subtitle {
    color: red !important;
}

.services-tile {
  position: relative;
  background: rgba(27, 32, 51, 0.5);
  background: linear-gradient(to bottom right, rgba(27, 32, 51, 0.6), rgba(27, 32, 51, 0.8));
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 220px;
  min-width: 325px;
  overflow: hidden;
  transition: transform 0.3s ease, background 0.3s ease, border 0.3s ease;
}


.services-tile::before,
.services-tile::after {
  content: "";
  position: absolute;
  border: 3px solid #ff8a00; /* Border color and thickness */
  width: 3rem; /* Width of the corner border */
  height: 3rem; /* Height of the corner border */
}

.services-tile::before {
  top: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-width: 1px 0 0 1px;
}

.services-tile::after {
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 15px;
  border-width: 0 1px 1px 0;
}

.services-tile .top-right::before,
.services-tile .bottom-left::after {
  content: "";
  position: absolute;
  border: 1px solid #ff8a00;
  width: 3rem;
  height: 3rem;
}

.services-tile .top-right::before {
  top: 0;
  right: 0;
  border-top-right-radius: 15px;
  border-width: 1px 1px 0 0;
}

.services-tile .bottom-left::after {
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-width: 0 0 1px 1px;
}


.home-services-tile {
  position: relative;
  background: rgba(27, 32, 51, 0.5);
  background: linear-gradient(
    to bottom right,
    rgba(27, 32, 51, 0.6),
    rgba(27, 32, 51, 0.8)
  );
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 160px;
  min-width: 17%;
  overflow: hidden;
  transition: transform 0.3s ease, background 0.3s ease, border 0.3s ease;
}

.home-services-tile::before,
.home-services-tile::after {
  content: "";
  position: absolute;
  border: 1px solid #ff8a00; /* Orange color for the edge borders */
  width: 3rem;
  height: 3rem;
  z-index: 1;
}

.home-services-tile::before {
  top: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-width: 1px 0 0 1px;
}

.home-services-tile::after {
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 15px;
  border-width: 0 1px 1px 0;
}

.home-services-tile .top-right::before,
.home-services-tile .bottom-left::after {
  content: "";
  position: absolute;
  border: 1px solid #ff8a00;
  width: 12px;
  height: 12px;
}

.home-services-tile .top-right::before {
  top: 0;
  right: 0;
  border-top-right-radius: 15px;
  border-width: 1px 1px 0 0;
}

.home-services-tile .bottom-left::after {
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-width: 0 0 1px 1px;
}


.marketplace-tile {
  position: relative;
  background: rgba(27, 32, 51, 0.5);
  background: linear-gradient(to bottom right, rgba(27, 32, 51, 0.6), rgba(27, 32, 51, 0.8));
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 220px;
  min-width: 35rem;
  max-width: 35rem !important;
  overflow: hidden;
  padding: 1.5rem;
  line-height: 1.65rem;
  font-size: 1.1rem !important;
  text-align: justify;
}

.marketplace-tile::before,
.marketplace-tile::after {
  content: "";
  position: absolute;
  border: 3px solid #ff8a00; /* Border color and thickness */
  width: 5rem; /* Width of the corner border */
  height: 5rem; /* Height of the corner border */
}

.marketplace-tile::before {
  top: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-width: 3px 0 0 3px;
}

.marketplace-tile::after {
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 15px;
  border-width: 0 3px 3px 0;
}

.marketplace-tile .top-right::before,
.marketplace-tile .bottom-left::after {
  content: "";
  position: absolute;
  border: 3px solid #ff8a00;
  width: 5rem;
  height: 5rem;
}

.marketplace-tile .top-right::before {
  top: 0;
  right: 0;
  border-top-right-radius: 15px;
  border-width: 3px 3px 0 0;
}

.marketplace-tile .bottom-left::after {
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-width: 0 0 3px 3px;
}

.services-why-choose-us-tile {
  min-width: 35rem;
  max-width: 100vw !important;

}


.marketplace-tile li {
  text-align: left !important;
  padding: 0.5rem 0;
  color: #ffffff; /* Ensures text remains white */
}

.marketplace-tile li::marker {
  color: #ff8a00; /* Sets the bullet color to #ff8a00 */
}

.marketplace-logo {
    height: 5rem;
}

.services-tile:hover {
    transform: translateY(-4px) scale(1.01); /* Slight lift on hover */
    background: linear-gradient(
      to bottom right,
      rgba(27, 32, 51, 0.7),
      rgba(27, 32, 51, 0.9)
    ); /* Darker gradient on hover for a more intense look */
    backdrop-filter: blur(15px) saturate(180%);
    -webkit-backdrop-filter: blur(15px) saturate(180%);
}  

.services-contact-container {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.services-icon {
    font-size: 2rem;
    color: #ff8a00 !important;
    margin-bottom: 0.1rem;
    transition: color 0.3s ease;
  }

.gradient-background {
    background: linear-gradient(145deg, #0e174d, #273a53); /* Gradient from dark blue to lighter blue */
    position: relative;
    padding: 50px;
    border-radius: 20px; /* Rounded corners like in the example */
    overflow: hidden; /* Ensure no elements overflow the container */
}

/* Adding blue square-like patterns */
.gradient-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;/* You can use a transparent PNG image for the square effect */
    opacity: 0.2; /* Make the squares subtle */
    pointer-events: none; /* Prevent interaction with the overlay */
    z-index: 1; /* Keep the background overlay behind the content */
}

/* Ensure content is above the gradient and overlay */
.gradient-background-content {
    position: relative;
    z-index: 2; /* Ensure the content is on top */
    color: #fff;
}

.text-900 {
    font-weight: 900;
}

.btn-services-calculate-savings {
    padding: 10px 40px; /* Padding for desktop */
    background-color: #ff8a00; /* Transparent background */
    color: #000; /* Light text color */
    border: 1px solid #000; /* White border */
    border-radius: 20px; /* Rounded corners */
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    width: auto; /* Default width for desktop */
    text-align: center;
    margin-top: 2rem;
}

.btn-sign-up {
  background-color: #ff8a00; /* Orange color */
  color: #ffffff; /* White text */
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-sign-up:hover {
  background-color: #ff9c33; /* Lighter orange on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.btn-sign-up:active {
  transform: scale(0.98); /* Slightly shrink on click */
}



.btn-services-calculate-savings:hover {
    color: #000 !important; /* Transparent text on hover */
    background-color: #ffffff; /* White background on hover */ 
}

/* Stack text and image vertically on smaller screens */
@media (max-width: 720px) {

    .first-title-container {
        margin-top: 1rem;
        font-size: 2.2rem !important;
    }

    .first-container-subtitle {
        font-size: 1.1rem !important;
    }

    .experience-subtitle, .benefits-subtitle {
        margin: 0;
        font-size: 1.1rem !important;
    }

    .landing-container-image img {
        width: 80vw; /* Scale the image down on smaller screens */
        height: auto;
    }

    .gradient-background {
        padding: 50px 20px;
        height: 25rem;
        min-height: 25rem;
    }

    .btn-services-contact, .btn-services-contact-2, .btn-services-calculate-savings {
        width: 100%; /* Button takes full width on mobile */
    }

    .btn-services-calculate-savings { 
      font-size: 1rem;
    }

    .marketplace-tile {
        min-width: 100%;
        max-width: 100% !important;
    }

    .padding-none {
      padding: 0rem !important;
    }

    .mw-90 {
      max-width: 90% !important;
    }

    .mnw-100 {
      min-width: 100% !important;
    }
    .contact-form {
      width: 90% !important;
    }
    .contact-form * {
      width: 100% !important;
    }

    .experience-images {
      height: 4rem;
    }
}
@media (max-width: 320px) {
    .landing-container-image img {
        margin-top: 6rem !important;
    }
}



.home-services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.home-services-tile {
    position: relative;
    background: rgba(27, 32, 51, 0.5);
    background: linear-gradient(
      to bottom right,
      rgba(27, 32, 51, 0.6),
      rgba(27, 32, 51, 0.8)
    );
    backdrop-filter: blur(12px) saturate(180%);
    -webkit-backdrop-filter: blur(12px) saturate(180%);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 160px;
    width: 18%; /* Set initial width for desktop */
    max-width: 200px; /* Maximum width for larger screens */
    overflow: hidden;
    transition: transform 0.3s ease, background 0.3s ease, border 0.3s ease;
    padding: 1rem;
    text-align: center;
}

.services-icon {
    font-size: 2rem;
    color: #ff8a00;
    margin-bottom: 0.5rem;
}

.home-services-p {
    font-size: 1.2rem !important;
}

.home-services-p-1rem {
  font-size: 1.1rem !important;
}

.marketplace-page-integration-section {
  width: 75% !important;
}

.home-services-tile h4 {
    font-weight: 900;
    margin-top: 0.5rem;
    font-size: clamp(1rem, 2.5vw, 1.2rem); /* Dynamic font-size adjustment */
    white-space: nowrap; /* Ensures text remains on a single line */
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff !important;
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .home-services-tile {
        width: 30%; /* Three tiles per row on medium screens */
    }
}

@media (min-width: 360px) {
    .home-services-tile {
        width: 45%; /* Two tiles per row on mobile screens */
    }
    .faq-section {
      padding: 0 !important;
    }
    .home-services-p {
      padding: 0 1rem !important;
    }
    .faq-answer {
      padding: 20px 0 !important;
      margin-top: 20px;
    }
}

@media (max-width: 359px) {
    .home-services-tile {
        width: 100%; /* One tile per row on extra-small screens */
    }
    .home-services-p {
      padding: 0 1rem !important;
    }
}



.faq-section {
    max-width: 90vw;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
  }
  
  .faq-title {
    font-size: 2rem;
    color: #ff8a00;
    margin-bottom: 2rem;
  }
  
  .faq-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr; /* 1 column by default */
    width: 100%; 
  }
  
  .faq-flip-card {
    width: 100%;
}

.faq-card-inner {
    position: relative;
    width: 100%;
    height: 160px;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    cursor: pointer;
}

.faq-card-inner:hover {
    transform: rotateY(180deg);
}

.faq-card-front, .faq-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
    color: #ffffff;
    font-size: 1rem;
}

/* Front card styling */
.faq-card-front {
    background: linear-gradient(to bottom right, rgba(27, 32, 51, 0.6), rgba(27, 32, 51, 0.8));
    backdrop-filter: blur(12px) saturate(180%);
    -webkit-backdrop-filter: blur(12px) saturate(180%);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Back card styling */
.faq-card-back {
    background: linear-gradient(to bottom right, rgba(20, 50, 70, 0.8), rgba(25, 40, 55, 0.9));
    transform: rotateY(180deg);
}

/* Corner border styles for both front and back */
.faq-card-front::before,
.faq-card-front::after,
.faq-card-back::before,
.faq-card-back::after {
    content: "";
    position: absolute;
    border: 2px solid #ff8a00; /* 2px orange border for corners */
    width: 4rem; /* Adjust the size of the corner border */
    height: 2rem;
    z-index: 1;
}

/* Top-left corner */
.faq-card-front::before,
.faq-card-back::before {
    top: 0;
    left: 0;
    border-top-left-radius: 15px;
    border-width: 2px 0 0 2px;
}

/* Bottom-right corner */
.faq-card-front::after,
.faq-card-back::after {
    bottom: 0;
    right: 0;
    border-bottom-right-radius: 15px;
    border-width: 0 2px 2px 0;
}

/* Additional corner borders for top-right and bottom-left */

/* Top-right corner */
.faq-card-front .top-right::before,
.faq-card-back .top-right::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border: 2px solid #ff8a00;
    border-top-right-radius: 15px;
    width: 2rem;
    height: 2rem;
    border-width: 2px 2px 0 0;
}

/* Bottom-left corner */
.faq-card-front .bottom-left::after,
.faq-card-back .bottom-left::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border: 2px solid #ff8a00;
    border-bottom-left-radius: 15px;
    width: 2rem;
    height: 2rem;
    border-width: 0 0 2px 2px;
}

/* Question and Answer Text Styling */
.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    color: #ff8a00; /* Color for question text */
    margin: 0;
    color: #fff !important;
}

.faq-answer {
    color: #ddd; /* Color for answer text */
    font-size: 1rem;
    padding: 1rem;
}

  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .faq-card-inner {
      min-height: 220px;
      height: auto;
    }
  }

  @media (min-width: 768px) {
    .faq-grid {
      grid-template-columns: 1fr 1fr; /* 2 columns on desktop */
    }
  }

@media (max-width: 720px) {
    .home-testimonial-tile {
        height: auto;
        min-height: 275px;
        padding: 20px 10px !important;
        width: 100% !important;
        min-width: 100% !important;
    }
    .marketplace-page-integration-section {
      width: 100% !important;
    }
}


.contact-section {
  background: rgba(27, 32, 51, 0.5);
  background: linear-gradient(
    to bottom right,
    rgba(27, 32, 51, 0.6),
    rgba(27, 32, 51, 0.8)
  );
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  color: #ffffff;
  border: 1px solid #ff8a00;
}


.home-testimonial-tile {
  position: relative;
  background: rgba(27, 32, 51, 0.5);
  background: linear-gradient(
    to bottom right,
    rgba(27, 32, 51, 0.6),
    rgba(27, 32, 51, 0.8)
  );
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  transition: transform 0.3s ease, background 0.3s ease, border 0.3s ease;
}

/* Corner border styles */
.home-testimonial-tile::before,
.home-testimonial-tile::after {
  content: "";
  position: absolute;
  border: 2px solid #ff8a00; /* 2px orange border for corners */
  width: 6rem; /* Adjusts the size of the corner border */
  height: 3rem;
  z-index: 1;
}

/* Top-left corner */
.home-testimonial-tile::before {
  top: 0;
  left: 0;
  border-top-left-radius: 15px;
  border-width: 2px 0 0 2px;
}

/* Bottom-right corner */
.home-testimonial-tile::after {
  bottom: 0;
  right: 0;
  border-bottom-right-radius: 15px;
  border-width: 0 2px 2px 0;
}

/* Additional corners: top-right and bottom-left */
.home-testimonial-tile .top-right::before,
.home-testimonial-tile .bottom-left::after {
  content: "";
  position: absolute;
  border: 2px solid #ff8a00;
  width: 12px;
  height: 12px;
}

.home-testimonial-tile .top-right::before {
  top: 0;
  right: 0;
  border-top-right-radius: 15px;
  border-width: 2px 2px 0 0;
}

.home-testimonial-tile .bottom-left::after {
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 15px;
  border-width: 0 0 2px 2px;
}

  
  /* Title styling */
  .testimonial-title {
    font-size: 2rem;
    color: #ff8a00;
    margin-bottom: 2rem;
    
  }
  
  /* Tile styling for testimonials */
  .testimonial-tile {
    width: 100%;
    max-width: 100% !important;
    margin: 0 auto;
  }
  
  /* Content inside each testimonial card */
  .tile-content {
    position: relative;
    width: 100%;
    padding: 2rem;
    border-radius: 15px;
    background: linear-gradient(to bottom right, rgba(27, 32, 51, 0.6), rgba(27, 32, 51, 0.8));
    color: #ffffff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
    text-align: center;
  }
  
  /* Text and customer details in the tile */
  .testimonial-text {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .testimonial-customer {
    font-weight: bold;
    font-size: 1rem;
  }
  
  .testimonial-company {
    font-size: 1.1rem;
    color: #ddd;
  }
  .testimonial-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .prev-slide, .next-slide {
    background: none;
    border: none;
    color: #ff8a00;
    font-size: 2rem;
    cursor: pointer;
    transition: color 0.3s, transform 0.3s;
  }
  
  .prev-slide:hover, .next-slide:hover {
    color: #fff;
    transform: scale(1.1);
  }
  
  .testimonial-dots {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #ff8a00;
  }
  

  hr {
    color: #ff8a00 !important;
  }

  .services-why-choose-us-ul li {
    text-align: left;
    margin: 1rem 0;
  }

  .services-first-image, .pricing-first-image {
    height: 30rem !important;
  }

  .guide-video {
    height: 400px;
    width: 700px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 720px) {
    .pricing-first-image-div, .pricing-first-image, .services-first-image-div, .services-first-image {
        display: none;
    }
    .guide-video {
      height: 225px;
      width: auto;
    }
  }
  .guide-login-a-li {
    font-size: 1rem !important;
  }