/* For mobile screens */
@media (max-width: 720px) {
    .form-title {
        text-align: center; /* Center-align form titles */
        margin-bottom: 8px;
    }

    .margin-bottom-6px {
        margin-bottom: 12px !important; /* Add spacing between inputs */
    }

    .form-control {
        width: 100%; /* Ensure inputs take full width */
    }

    .btn {
        width: 100%; /* Ensure the button spans the full width */
    }
}
