.white-background {
    background-color: white;
}
.table-custom-radius {
    border-radius: 20px !important;
    overflow: hidden;
    background-color: white;
}
.table-custom-radius td, .table-custom-radius th {
    text-align: center;
    vertical-align: middle;
    padding: 10px;
}