@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
html {
  scroll-behavior: smooth !important;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
}

.dashboard,
.reports,
.products {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.bg-blue {
  background-color: #060b26 !important;
}
h1, h2, h3, h4, h5, h6 {
  color: #1b2033 !important;
}
button, a, input[type="submit"] {
  border-radius: 10px !important;
}
.border-radius-20 {
  border-radius: 20px !important;
}
.font-orange {
  color: #ff8a00 !important;
}
.bg-orange {
  background-color: #ff8a00 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
table tr td {
  background-color: #fff !important;
}
table tr {
  background-color: #fff !important;
}