.appointmentContainer {
    width: 100vw;
    margin: 0 auto;
    padding: 10px;
    border-radius: 20px;
    background: rgba(27, 32, 51, 0.5);
    background: linear-gradient(
      to bottom right,
      rgba(27, 32, 51, 0.6),
      rgba(27, 32, 51, 0.8)
    );
    backdrop-filter: blur(12px) saturate(180%);
    -webkit-backdrop-filter: blur(12px) saturate(180%);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
}
.appointment-title {
  margin-bottom: -2rem;
}
.calendar {
  height: 45rem;
  width: 20rem;
}
/************ MOBILE ************/
@media (max-width: 720px) {
  .appointment-title {
    margin-bottom: 2rem;
  }
}