
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.bg-dark {
  background-color: #1b2033;
}
.text-white {
  color: white;
}
.relative {
  position: relative;
}
.overflow-hidden {
  overflow: hidden;
}
.w-full {
  width: 100%;
}
.relative {
  position: relative;
}
.z-10 {
  z-index: 10;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.font-bold {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.h-2 {
  height: 0.5rem;
}
.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-purple-400 {
  --tw-gradient-from: #a78bfa;
}
.via-pink-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.to-red-500 {
  --tw-gradient-to: #ef4444;
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: 2rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.md\:text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.md\:text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-gray-300 {
  color: #d1d5db;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-left: 1rem;
}
.bg-white {
  background-color: white;
}
.text-black {
  color: black;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.rounded-full {
  border-radius: 9999px;
}
.hover\:bg-gray-200:hover {
  background-color: #e5e7eb;
}
.transition-all {
  transition-property: all;
}
.duration-300 {
  transition-duration: 300ms;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.border {
  border-width: 1px;
}
.border-white {
  border-color: white;
}
.hover\:bg-white:hover {
  background-color: white;
}
.hover\:text-black:hover {
  color: black;
}
.absolute {
  position: absolute;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.opacity-75 {
  opacity: 0.75;
}
.blur-2xl {
  filter: blur(1.5rem);
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
.items-center {
  align-items: center;
}
.p-2 {
  padding: 0.5rem;
}
.bg-opacity-10 {
  background-color: rgba(255, 255, 255, 0.1);
}
.rounded-full {
  border-radius: 9999px;
}
.w-16 {
  width: 4rem;
}
.h-16 {
  height: 4rem;
}
.object-contain {
  object-fit: contain;
}
.gradient-text {
  background: none;
  color: white !important;
  background-clip: initial;
  -webkit-background-clip: initial;
  -webkit-text-fill-color: white; 
}
.typewriter {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
}
.typewriter h1 {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 1.5s steps(40, end);
  margin: 0;
  letter-spacing: 0.1em;
  padding-right: 0.2em;
  font-family: "Lato", sans-serif !important;
  font-size: 3rem;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.caret {
  border-right: 2px solid rgba(255, 255, 255, 0.75);
  animation: blink-caret 0.75s step-end infinite, disappear-caret 1s ease-in-out 1.5s forwards;
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255, 255, 255, 0.75);
  }
}

@keyframes disappear-caret {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
:root {
  --bg-color: #f4f5f9;
}
.home-title span {
  position: relative;
  overflow: hidden;
  display: block;
  line-height: 1.2;
}
.home-title span::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(-101%);
}
.home-title span::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(0);
}
.home-title span:nth-of-type(1)::before,
.home-title span:nth-of-type(1)::after {
  animation-delay: 1s;
}
.home-title span:nth-of-type(2)::before,
.home-title span:nth-of-type(2)::after {
  animation-delay: 2.5s;
}
@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
.moving-gradient {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    #343399,
    #ff8a00,
    #e52e71,
    #9d50bb
  );
  background-size: 200%;
  opacity: 0.8;
  animation: glowMove 3s ease infinite, glowPulse 2s ease-in-out infinite;
}
@keyframes glowMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes glowPulse {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}
.container22 {
  padding: 5px;
  margin: 0 auto;
}
.reveal-text {
  margin: 0;
  text-align: center;
  overflow: hidden;
  line-height: 1.5;
  font-size: 22px !important;
  margin-top: -30px !important;
  color: white !important;
}
.reveal-text span {
  display: block;
  animation: reveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0s;
}
@keyframes reveal {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.comet {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 10px;
  height: 10px;
  background: radial-gradient(circle, white, transparent);
  border-radius: 50%;
  box-shadow: 0 0 15px white, 0 0 30px white, 0 0 45px #ff8a00, 0 0 60px #ff8a00;
  transform: translateX(-50%);
}
.comet-1 {
  animation: cometFlyLeft 5s ease-in-out infinite;
}
@keyframes cometFlyLeft {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(-400px, -200px) scale(0.7);
    opacity: 0.7;
  }
  100% {
    transform: translate(-800px, -400px) scale(0.5);
    opacity: 0;
  }
}
.comet-2 {
  animation: cometFlyUp 5s ease-in-out infinite;
}
@keyframes cometFlyUp {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(0, -400px) scale(0.7);
    opacity: 0.7;
  }
  100% {
    transform: translate(0, -800px) scale(0.5);
    opacity: 0;
  }
}
.comet-3 {
  animation: cometFlyRight 5s ease-in-out infinite;
}
@keyframes cometFlyRight {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(400px, -200px) scale(0.7);
    opacity: 0.7;
  }
  100% {
    transform: translate(800px, -400px) scale(0.5);
    opacity: 0;
  }
}
.benefit-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 0 auto;
  padding: 2rem !important;
  text-align: center;
  justify-content: center;
}

.home-tile {
  position: relative;
  background: none;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 220px;
  min-width: 325px;
  transition: transform 0.3s ease, background 0.3s ease, border 0.3s ease;
  z-index: 1;
}

.home-tile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(27, 32, 51, 0.5);
  background: linear-gradient(
    to bottom right,
    rgba(27, 32, 51, 0.6),
    rgba(27, 32, 51, 0.8)
  );
  backdrop-filter: blur(12px) saturate(180%);
  -webkit-backdrop-filter: blur(12px) saturate(180%);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  z-index: -1; /* Make sure it stays behind the content */
}

.home-tile:hover::before {
  background: linear-gradient(
    to bottom right,
    rgba(27, 32, 51, 0.7),
    rgba(27, 32, 51, 0.9)
  );
  backdrop-filter: blur(15px) saturate(180%);
  -webkit-backdrop-filter: blur(15px) saturate(180%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  
}

.home-tile:hover {
  transform: translateY(-8px) scale(1.02) !important;
}

.home-tile * {
  position: relative;
  z-index: 2; /* Ensure all content stays above the blurred background */
}


.home-tile h3 {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: white !important;
  transition: color 0.3s ease;
}
.home-tile:hover h3 {
  color: #f4f5f9 !important;
}
.home-tile p {
  font-size: 1rem;
  color: white !important;
  margin-top: 0.2rem;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
  padding: 0.5rem !important;
}
.home-tile:hover p {
  color: #f4f5f9 !important;
}
.home-tile .icon {
  font-size: 3rem;
  color: #ff8a00;
  margin-bottom: 0.1rem;
  transition: color 0.3s ease;
}
.home-tile:hover .icon {
  color: white;
}
.contact-tile {
  min-height: 20rem !important;
  min-width: 40rem !important;
}

.background-image {
  position: relative;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
}

.mt-5rem {
  margin-top: 5rem;
}

.register-business-background-image {
  margin-top: 0 !important;
}
.background-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #060b26;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

a {
  color: white !important;
  font-size: 1.2rem !important;
}
.platformIcon:hover {
  transition: transform 0.4s ease, background 0.3s ease, border 0.3s ease;
  transform: translateY(-10px) scale(1.5);
}
.btn-login-tw:hover {
  transition: transform 0.4s ease, background 0.3s ease, border 0.3s ease;
  transform: translateY(-1px) scale(1.1);
}
.contact-form-input {
  width: 30rem !important;
}
.forgot-password {
  font-size: 1rem !important;
  text-decoration: underline;
}
.background-image-login {
  position: relative;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
}
.background-image-login::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/home-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.background-image-login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - var(--menuHeight, 0px));
  padding: 20px;
  box-sizing: border-box;
}
.login-card {
  background-color: #ffffff;
  padding: 40px !important;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 35rem !important;
}
.new-login-card {
  background-color: #ffffff;
  padding: 40px !important;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 100% !important;
}
.font-orange {
  color: #ff8a00 !important;
}
.font-1rem {
  font-size: 1rem !important;
}

/*MOBILE*/
@media (max-width: 720px) {
  .login-card {
    padding: 30px 15px !important;
  }
  .myContainer {
    margin: 0 auto !important;
  }
  .typewriter h1 {
    margin-top: 40px !important;
    font-size: 3rem;
    margin-left: 10px !important;
  }
  .reveal-text {
    margin-left: -10px !important;
  }
  .container22,
  .typewriter {
    margin-top: 2rem !important;
  }
  .text-center, .benefit-tiles, .flex {
    justify-content: center;
    align-items: center;
  }
  .benefit-tiles {
    grid-template-columns: 1fr;
    max-width: 80vw !important;
  }
  .flex > a {
    margin-bottom: 1rem;
  }
  .logo-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 2rem;
  }
  .logo-row-contact {
    display: grid;
    gap: 1rem;
    margin-top: 4rem;
  }
  .moving-gradient {
    margin-top: 2rem;
  }
  .contact-tile {
    min-height: 20rem !important;
    min-width: 75vw !important;
  }
  .home-tile:hover {
    transform: none;
  }
  .login-card {
    max-width: 95vw !important;
  }
  .benefit-tiles {
    grid-template-columns: 1fr;
  }
  .contact-form-input {
    width: 17rem !important;
  }
}
@media (min-width: 360px) { /* Adjust for small screens */
  .landing-container-text, .landing-container-image {
      text-align: center !important; /* Center content within each column */
      margin-left: auto !important; /* Center-align columns */
      margin-right: auto !important;
      padding-left: 0 !important; /* Center-align columns */
      padding-right: 0 !important;
  }
  .landing-container-image img {
      max-width: 100%; /* Ensure image is responsive */
      height: auto;
  }
  .row {
    text-align: center !important; /* Center content within each column */
      margin-left: auto !important; /* Center-align columns */
      margin-right: auto !important;
      padding-left: 0 !important; /* Center-align columns */
      padding-right: 0 !important;
  }
}

.blog-list-tile-link {
  text-decoration: none !important;
}
.blog-list-tile-title {
  text-decoration: underline;
  text-decoration-color: #ff8a00;
}
.blog-list-tile-subtitle {
  text-decoration: none !important;
}
.blog-content {
  padding: 0.5rem 2rem;
}
.blog-content h2 {
  color: #ff8a00 !important;
  font-size: 1.5rem !important;
}
.blog-content p {
  font-size: 1.1rem !important;
}
.blog-content li {
  text-align: left !important;
}
@media (min-width: 360px) {
  .blog-content {
    padding: 0.25rem;
  }
}