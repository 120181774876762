body {
    background-color: #f4f5f9;
    font-family: BMDupletTXT,HelveticaTXT,sans-serif;
}
  
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-container select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.pagination-container button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 8px 12px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-container button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.pagination-container span {
    font-weight: bold;
}
.invoices-dropdown {
    margin-right: 5px;
}

.padding-0-2 {
    padding: 0.2rem !important;
}

.messageBox {
    border-radius: 15px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.messageBox:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.tile {
    width: 100%;
    height: 9rem !important;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Arial, sans-serif;
    position: relative;
}

.tile:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.tile-icon {
    font-size: 25px;
    margin-top: 10px;
}

.tile-invoices {
    font-size: 18px;
    font-weight: bold;
}

.tile-platform {
    font-size: 15px;
    font-weight: lighter;
}

.tile-link {
    font-size: 15px;
    font-weight: lighter;
    text-decoration: underline !important;
}

.tile-extra-info {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    text-decoration: underline;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Transparent background */
    z-index: 1000; /* Ensures it's on top of everything */
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.margin-bottom-6px {
    margin-bottom: 6px !important;
}

.form-title {
    text-align: right;
}

.bg-backmarket {
    background-color: #f5a623;
    border: none;
}
.bg-refurbed {
    background-color: #4a90e2;
    border: none;
}
.bg-backmarket:hover {
    background-color: #f5a623;
    border: none;
}
.bg-refurbed:hover {
    background-color: #4a90e2;
    border: none;
}
.padding-0-3 {
    padding: 0.3rem !important;
}
.btn-w25-w50 {
    width: 25%;
    margin-right: 2rem;
}
@media (max-width: 720px) {
    .form-title {
        text-align: center;
    }
    .invoices-dropdown {
        display: none;
    }
    .dashboard-tile {
        margin-top: 1rem !important;
    }
    .btn-w25-w50 {
        width: 50%;
        margin: 0 1rem;
    }
}
.chart-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}